const alertData = [
    {
        id: 1,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Braquage',
    },
    {
        id: 2,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Couvre feu',
    },
    {
        id: 3,
        image: require('@/assets/images/small/sos.jpg'),
        text: 'Discours présidentiel',
    }
];

export { alertData };